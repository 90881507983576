import React from "react"

const Form = () => {
  return (
    <div className="Contact-Wrapper">
      <div className="stripe" />
      <div className="section">
        <div className="wrap" id="CTA">
          <form
            name="contact-form"
            method="POST"
            onSubmit="submit"
            data-netlify-honeypot="bot-field"
            data-netlify="true"
            novalidate
            enctype="multipart/form-data"
            action="/thank-you"
            className="ContactSection-Form"
          >
            <input type="hidden" name="form-name" value="contact-form" />
            <div className="ContactSection-FieldGroup">
              <div className="ContactSection-Fieldset">
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="Adınız *"
                  aria-label="Your first name"
                  className="ContactSection-Field"
                  required
                />
                <p className="ContactSection-FormError" />
              </div>
              <div className="ContactSection-Fieldset">
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder="Soyadınız *"
                  aria-label="Your last name"
                  className="ContactSection-Field"
                  required
                />
                <p className="ContactSection-FormError" />
              </div>
            </div>
            <div className="ContactSection-FieldGroup">
              <div className="ContactSection-Fieldset">
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email *"
                  aria-label="Your email address"
                  className="ContactSection-Field"
                  required
                />
                <p className="ContactSection-FormError" />
              </div>
              <div className="ContactSection-Fieldset">
                <input
                  type="text"
                  id="companyName"
                  name="companyName"
                  placeholder="Telefon *"
                  aria-label="Your company name"
                  className="ContactSection-Field"
                  required
                />
                <p className="ContactSection-FormError" />
              </div>
            </div>
            <div className="ContactSection-Fieldset">
              <textarea
                placeholder="Mesajınız"
                id="message"
                name="message"
                rows={4}
                aria-label="Mesajınız"
                className="ContactSection-Message"
                defaultValue={""}
              />
              <p className="ContactSection-FormError" />
            </div>
            <button type="submit" className="btn nobg">
              Gönder
            </button>
          </form>
        </div>
      </div>
      <div className="stripe" />
    </div>
  )
}

export default Form
