import React, { Fragment } from "react"
import { Form } from "../components/Contact/index"
import { Hero } from "../components/StandartPage/index"

import Seo from "../components/seo"

const ContactPage = () => (
  <Fragment>
    <Seo
      title="İletişim"
      description="İletişim formu doldurarak bize 7/24 ulaşabilirsiniz. Ve 05445883442 nolu telefondan arayın."
    />
    <Hero
      title="Bizimle İletişime Geçin"
      subtitle="Aşağıdaki formu doldurarak bize 7/24 ulaşabilirsiniz. +90 544 588 34 42 no'lu telefondan bizi arayabilirsiniz."
    />
    <Form />
  </Fragment>
)

export default ContactPage
